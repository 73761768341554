<template>
  <div>
    <public-header />
    <div class="wrapper smsServicePageWrapper">
      <div class="page-header page-header-small">
        <div
          class="page-header-image"
          data-parallax="true"
        />
        <div class="content-center mt-4">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <img
                  src="https://www.clicksend.com/img/ic-mms_product_page.svg"
                  alt="alt"
                >
                <h1>Business MMS Gateway</h1>
                <p>Send Multimedia Messages Worldwide Receive and Track with Ease.</p>
                <button
                  v-if="isUserLoggedIn"
                  class="getSTartedBtn"
                  @click="$router.push('/pages/dashboard')"
                >
                  Goto dashboard
                </button>
                <button
                  v-if="!isUserLoggedIn"
                  class="getSTartedBtn"
                  @click="$router.push('/register')"
                >
                  Get Started Now!
                </button>
              </div>
              <div class="col-md-6" />
            </div>
          </div>
        </div>
        <img
          class="landingBackgroundGraphic"
          src="https://www.clicksend.com/img/img-mms_header.svg"
          alt="alt"
        >
      </div>
      <div class="container overviewSectionWrapper">
        <div class="row">
          <div class="col-12 overviewSectionHeaderContents">
            <h1>Take Your Business Messaging to Another Level With MMS</h1>
            <p class="sub1">
              Easily send your customers and staff multimedia content to seriously enhance engagement
            </p>
          </div>
          <div class="col-md-6 overViewCardWrapper">
            <div class="overViewCard">
              <img
                src="https://www.clicksend.com/img/ic-shared_online_mms_gateway.svg"
                alt="alt"
              >
              <div class="textSection">
                <h4>Online MMS Gateway</h4>
                <p>ClickSend’s online Multimedia Messaging Service (MMS) gateway allows staff to send and receive MMS communications from any web enabled device.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 overViewCardWrapper">
            <div class="overViewCard">
              <img
                src="https://www.clicksend.com/img/ic-shared_mms_gateway_api.svg"
                alt="alt"
              >
              <div class="textSection">
                <h4>MMS Gateway API</h4>
                <p>MMS Gateway API enables integration directly with business applications, websites and software. Our flexible and powerful set of APIs enable developers to add MMS to their applications.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 overViewCardWrapper">
            <div class="overViewCard">
              <img
                src="https://www.clicksend.com/img/ic-shared_bulk_mms_marketing.svg"
                alt="alt"
              >
              <div class="textSection">
                <h4>Bulk MMS Marketing</h4>
                <p>Bulk MMS can be sent using our Online MMS service or through your existing application using our secure API.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 overViewCardWrapper">
            <div class="overViewCard">
              <img
                src="https://www.clicksend.com/img/ic-shared_smpp_mms_api.svg"
                alt="alt"
              >
              <div class="textSection">
                <h4>SMPP API</h4>
                <p>Send high volume MMS through our high performance SMPP gateway. If you’re looking for a high throughput MMS aggregator, this is the product you’re looking for..</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="smsGatewayWrapper">
        <div class="container">
          <div class="row">
            <div class="col-12 smsGatewayWrapperInnerWrapper">
              <h1>What is MMS?</h1>
              <p>MMS stands for Multimedia Messaging Service. MMS is a standard protocol for sending multimedia content between devices. Including PNG, JPG or GIF files plus unlimited characters. In contrast to SMS, which is text only and restricted to 160 characters.</p>
            </div>
            <div class="col-12 smsGatewayInfo">
              <img
                src="https://www.clicksend.com/img/img-shared_mms_gateway_phone.svg"
                alt="alt"
              >
              <div class="textSection">
                <div class="descWrapper">
                  <p>
                    MMS messages are a visual, richer alternative to sending SMS messages. Incorporating MMS into your mobile campaigns allows you to be more creative. MMS allows brands to use images and GIFs to drive their desired outcomes, but MMS is a two-way street. Brands can also request and receive rich media from their audience. This makes MMS perfect for elevating competitions, requests and feedback.

                    Via the ClickSend online dashboard or API you can send mass MMS messages to your audience in seconds. That could be thousands of images, coupons or GIF files to your database in a few clicks. And with SMS/MMS having a 98% open rate your messages are being viewed and consumed.
                  </p>
                </div>
                <div class="descItems">
                  <p>
                    <i
                      class="fa fa-check blueText"
                      aria-hidden="true"
                    /> Animated promotional cards
                  </p>
                  <p>
                    <i
                      class="fa fa-check blueText"
                      aria-hidden="true"
                    /> Competitions
                  </p>
                  <p>
                    <i
                      class="fa fa-check blueText"
                      aria-hidden="true"
                    /> Coupons & promotions with scannable codes
                  </p>
                  <p>
                    <i
                      class="fa fa-check blueText"
                      aria-hidden="true"
                    /> Instructional GIFs
                  </p>
                  <p>
                    <i
                      class="fa fa-check blueText"
                      aria-hidden="true"
                    /> Special announcements
                  </p>
                </div>
                <button
                  v-if="isUserLoggedIn"
                  class="getSTartedBtn"
                  @click="$router.push('/pages/dashboard')"
                >
                  Goto dashboard
                </button>
                <button
                  v-if="!isUserLoggedIn"
                  class="getSTartedBtn"
                  @click="$router.push('/register')"
                >
                  Get Started Now!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container whyUseWrapper">
        <div class="row">
          <div class="col-12">
            <h1>Just a few benefits of the ClickSend MMS Gateway API</h1>
          </div>
          <div class="col-md-6 useSub1">
            <h3>Tell a richer more fulfilling story</h3>
            <p>Forget the restrictive character limits of SMS. Increase your engagement by telling a story or getting the right information to a customer through still and moving imagery.</p>
          </div>
          <div class="col-md-6 useSub1">
            <h3>And it’s so easy to send those stories</h3>
            <p>Our Online MMS Gateway is super simple to use. Just drag your media file onto our dashboard and see in real time what it looks like on a mobile device. You get to review exactly what your campaign looks like before sending.</p>
          </div>
          <div class="col-md-4 useSub2">
            <img
              src="https://www.clicksend.com/img/img-shared_mms_more_messaging.svg"
              alt="alt"
            >
            <h3>More Messaging</h3>
            <p>Access our 1,500-character limit and send your customers clear and concise information about your product or service</p>
          </div>
          <div class="col-md-4 useSub2">
            <img
              src="https://www.clicksend.com/img/img-shared_mms_merge_information.svg"
              alt="alt"
            >
            <h3>Merge Information</h3>
            <p>Personalize your messages by including customer information through our bulk MMS software</p>
          </div>
          <div class="col-md-4 useSub2">
            <img
              src="https://www.clicksend.com/img/img-shared_mms_picture_resizing.svg"
              alt="alt"
            >
            <h3>Picture Resizing</h3>
            <p>ClickSend online MMS Gateway automatically resizes your images to adhere to network restrictions</p>
          </div>
        </div>
      </div>
      <div class="standerdFeatureWrapper">
        <div class="standerdFeatureHeaderImageWrapper">
          <img
            src="https://www.clicksend.com/img/ic-shared-sms2_page.svg"
            alt="alt"
          >
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h4>Standard Features Included</h4>
              <div class="container standerdFeatureInnerWrapper">
                <div class="row">
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-people.svg"
                      alt="alt"
                    >
                    <p>Reliable – 100% uptime SLA guarantee</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-lifesaver.svg"
                      alt="alt"
                    >
                    <p>Amazing free 24/7 support</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-guage2.svg"
                      alt="alt"
                    >
                    <p>Lightning fast message delivery,</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-rocket.svg"
                      alt="alt"
                    >
                    <p>High performance – 4800 messages per minute</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-bar1.svg"
                      alt="alt"
                    >
                    <p>Guaranteed delivery with Tier 1 networks</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-globe.svg"
                      alt="alt"
                    >
                    <p>Send SMS online to all countries globally</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-note.svg"
                      alt="alt"
                    >
                    <p>Customize sender ID</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-pie1.svg"
                      alt="alt"
                    >
                    <p>Real-time message status monitoring</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-chat1.svg"
                      alt="alt"
                    >
                    <p>Secure 256-bit SSL</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-chat1.svg"
                      alt="alt"
                    >
                    <p>Long 1224 character limit</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-monitor.svg"
                      alt="alt"
                    >
                    <p>Access to our SMS portal</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-xls.svg"
                      alt="alt"
                    >
                    <p>Upload from CSV or Excel file for bulk SMS campaigns</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-people.svg"
                      alt="alt"
                    >
                    <p>We automatically handle opt-outs for marketing campaigns</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-person.svg"
                      alt="alt"
                    >
                    <p>Free fixed number</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-back.svg"
                      alt="alt"
                    >
                    <p>Free replies</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-setting1.svg"
                      alt="alt"
                    >
                    <p>No setup fees</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-check.svg"
                      alt="alt"
                    >
                    <p>No hidden charges</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-monitor.svg"
                      alt="alt"
                    >
                    <p>Access to our online SMS software</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-bell.svg"
                      alt="alt"
                    >
                    <p>Free low credit warning</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-card.svg"
                      alt="alt"
                    >
                    <p>Optional auto-topup</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-up.svg"
                      alt="alt"
                    >
                    <p>Free delivery reports</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-setting2.svg"
                      alt="alt"
                    >
                    <p>Free customization</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-play.svg"
                      alt="alt"
                    >
                    <p>No setup required. Be up and running in minutes.</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-wallet.svg"
                      alt="alt"
                    >
                    <p>Flexible prepaid and postpaid payment options available</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomQuestionsWrapper">
          <h2>Questions</h2>
          <p>Free accounts and free advice. We can’t wait to see what you create.</p>
          <button
            v-if="isUserLoggedIn"
            class="getSTartedBtn"
            @click="$router.push('/pages/dashboard')"
          >
            Goto dashboard
          </button>
          <button
            v-if="!isUserLoggedIn"
            class="getSTartedBtn"
            @click="$router.push('/register')"
          >
            Get Started Now!
          </button>
        </div>
      </div>
    </div>
    <public-footer />
  </div>
</template>

<script>
  export default {
    name: 'MMSServicePage',

    components: {
      PublicHeader: () => import('../components/PublicHeader'),
      PublicFooter: () => import('../components/PublicFooter'),
    },

    data: () => ({
      isUserLoggedIn: false,
    }),
    computed: {},
    created () {
      window.scrollTo(0, 0)
      this.checkIfUserLoggedIn()
    },
    methods: {
      checkIfUserLoggedIn () {
        const username = localStorage.getItem('username')
        if (username && username.length) {
          this.isUserLoggedIn = true
        }
      },
      redirectToPage (path) {
        window.location = window.location.origin + path
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";
@import '../../../assets/bootstrap.min.css';
@import '../../../assets/now-ui-kit.css';
.page-header-image {
  background-image: -webkit-gradient(linear,left top,right top,from(#792bfb),color-stop(60%,#a740fe),to(#a63ff6));
}
.page-header:before {
  background-color: rgba(8, 8, 8, 0);
}
.page-header.page-header-small {
  max-height: unset!important;
  // height: 600px!important;
  position: relative;
}
.getSTartedBtn {
  background-color: rgb(0, 76, 255);
  padding: 8px 35px;
  color: white;
  box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
  margin-bottom: 40px;
  margin-top: 40px;
  &:hover {
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.054);
  }
}
.landingBackgroundGraphic {
  position: absolute;
  right: 0;
  bottom: 0%;
  width: 450px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.overviewSectionWrapper {
  .overviewSectionHeaderContents {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 15px 45px;
    .sub1 {
      margin-top: 1rem;
      font-size: 1.5rem;
      color: rgb(155, 155, 155);
    }
    .sub2 {
      margin-top: 1.5rem;
      font-size: 1.6rem;
      color: rgb(155, 155, 155);
      margin-bottom: 2rem;
    }
  }
  .overViewCard {
    margin: 12px 25px;
    padding: 30px 40px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    box-shadow: 3px 3px 120px rgba(0, 0, 0, 0.081);
    .textSection {
      margin-left: 1.7rem;
      h4 {
        font-weight: 500;
      }
      p {
        font-size: 1rem;
        color: rgb(155, 155, 155);
        line-height: 24px;
      }
    }
  }
}
  .smsGatewayWrapper {
    padding: 3rem 1rem;
    background-color: #F3F8FD;
    .smsGatewayWrapperInnerWrapper {
      text-align: center;
    }
  }
  .smsGatewayInfo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .textSection {
      padding-left: 3.5rem;
      .descWrapper {
        p {
          font-size: 1.2rem;
          margin-top: -5px;
          margin-bottom: 4rem;
          color: rgb(155, 155, 155);
        }
      }
    }
  }
  .whyUseWrapper {
    padding: 9rem 0;
    text-align: center;
    .useSub1 {
      h3 {
        font-weight: 600;
      }
      p {
        font-size: 1.2rem;
          margin-top: -5px;
          margin-bottom: 4rem;
          color: rgb(155, 155, 155);
      }
    }
    .useSub2 {
      img {
        width: 90px;
      }
      h3 {
        margin-top: 2rem;
        font-size: 1.7rem;
        font-weight: 600;
      }
      p {
        font-size: 1.2rem;
          margin-top: -5px;
          margin-bottom: 4rem;
          color: rgb(155, 155, 155);
      }
    }
  }
  .standerdFeatureWrapper {
           background-image: linear-gradient(90deg,#792bfb 0,#a740fe 60%,#a63ff6);
      min-height: 800px;
      margin-top: 3rem;
      .standerdFeatureHeaderImageWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-top: -3rem;
          border: 8px solid white;
          border-radius: 100PX;
        }
      }
      h4 {
        width: 100%;
        text-align: center;
        font-size: 1.8rem;
        color: white;
        margin-top: 15px;
        margin-bottom: 20px;
      }
      .standerdFeatureInnerWrapper {
        width: 90%;
        box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        border-radius: 10px;
        padding: 50px 55px;
        background-color: white;
        margin-top: 35px;
        .col-md-6 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100px;
          text-align: left;
          img {
            width: 30px;
          }
          p {
            margin: 0;
            margin-left: 2rem;
            font-size: 1rem;
          }
        }
      }
    }
    .bottomQuestionsWrapper {
      margin-top: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      .getSTartedBtn {
          background-color: rgb(0, 76, 255);
          padding: 8px 35px;
          color: white;
          box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
          margin-bottom: 40px;
          margin-top: 10px;
          &:hover {
            box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.054);
          }
        }
    }
    .blueText {
      padding-bottom: 4px;
      padding: 3px;
      font-size: 0.8rem;
      background-color: rgb(29, 150, 255);
      border-radius: 60px;
      color: white;
    }
</style>
